exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sections-education-tsx": () => import("./../../../src/pages/sections/education.tsx" /* webpackChunkName: "component---src-pages-sections-education-tsx" */),
  "component---src-pages-sections-experience-tsx": () => import("./../../../src/pages/sections/experience.tsx" /* webpackChunkName: "component---src-pages-sections-experience-tsx" */),
  "component---src-pages-sections-links-tsx": () => import("./../../../src/pages/sections/links.tsx" /* webpackChunkName: "component---src-pages-sections-links-tsx" */),
  "component---src-pages-sections-projects-tsx": () => import("./../../../src/pages/sections/projects.tsx" /* webpackChunkName: "component---src-pages-sections-projects-tsx" */),
  "component---src-pages-sections-references-tsx": () => import("./../../../src/pages/sections/references.tsx" /* webpackChunkName: "component---src-pages-sections-references-tsx" */),
  "component---src-pages-sections-skills-tsx": () => import("./../../../src/pages/sections/skills.tsx" /* webpackChunkName: "component---src-pages-sections-skills-tsx" */)
}

